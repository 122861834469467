import React from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import * as graph from '../logic/graph';

function mapStateToProps(state: any): Props {
    const populatedGraph = graph.build(state.releases, state.updates);
    return {
        'nodes': populatedGraph[0],
        'edges': populatedGraph[1],
    }
}

interface Props {
    nodes: string[],
    edges: Array<[number, number]>,
}

class UpdatesGraph extends React.Component<Props> {
    private contentDiv = React.createRef<HTMLDivElement>()
    private svgGraph = React.createRef<SVGSVGElement>()
    private graphGroup = React.createRef<SVGGElement>()

    static defaultProps = {
        'nodes': [],
        'edges': []
    }

    componentDidUpdate(_prevProps: any) {
        this.effect()
    }

    componentDidMount() {
        this.effect()
    }

    effect() {
        if (!this.svgGraph.current || !this.svgGraph.current) {
            return;
        }
        const svgGraph = this.svgGraph.current!;
        const graphGroup = this.graphGroup.current!;


        const rendered = graph.render(this.props.nodes, this.props.edges);

        let render = new dagreD3.render();
        var inner: any = d3.select(graphGroup);
        render(inner, rendered);

        /*
        // Compute scale.
        var boxWidth = contentDiv.clientWidth;
        var graphWidth = rendered.graph().width;
        var scale = contentDiv.clientWidth / graphWidth;
        var newHeight = (rendered.graph().height * scale) + 40;
        */

        var zoomHandler: any = function ({ transform }: any) {
            graphGroup.setAttribute("transform", transform.toString())
        };

        var svg: any = d3.select(svgGraph);
        svg.call(d3.zoom()
            .scaleExtent([0.1, Infinity])
            .on("zoom", zoomHandler));
    }

    render() {
        return (
            <div ref={this.contentDiv} className="updates-graph p-d-flex">
                <svg ref={this.svgGraph} width="100%" height="100%">
                    <g ref={this.graphGroup} transform="" />
                </svg>
            </div>
        )
    }
}

const connector = connect(mapStateToProps, null)
export default connector(UpdatesGraph);