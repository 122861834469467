import { Octokit } from "@octokit/rest";

export async function checkout_updates(stream: string) {
    const octokit = new Octokit();
    var blob: any = await octokit.repos.getContent(
        {
            "owner": "coreos",
            "repo": "fedora-coreos-streams",
            "path": `updates/${stream}.json`
        }
    )
    var content = atob(blob.data.content);
    var updates = JSON.parse(content);
    return updates;
}
