export interface BarrierEntry {
    kind: string,
    paused: boolean,
    version: string,
    reason: string,
}

export function map_barriers(updates: any): Array<BarrierEntry> {
    var barriers: Array<any> = [];
    if (!("releases" in updates)) {
        return barriers;
    }

    const releases: any = updates.releases;

    for (let entry of releases) {
        if (!("barrier" in entry.metadata)) {
            continue;
        }
        const barrier = {
            kind: "barrier",
            paused: entry.metadata.barrier.paused || false,
            version: entry.version,
            reason: entry.metadata.barrier.reason || ("unknown"),
        }
        barriers.push(barrier);
    }
    return barriers
}

export interface DeadendEntry {
    kind: string,
    paused: boolean,
    version: string,
    reasion: string,
}

export function map_deadends(updates: any): Array<DeadendEntry> {
    var deadends: Array<any> = [];
    if (!("releases" in updates)) {
        return deadends;
    }

    const releases: any = updates.releases;

    for (let entry of releases) {
        if (!("deadend" in entry.metadata)) {
            continue;
        }
        const deadend = {
            kind: "deadend",
            paused: entry.metadata.deadend.paused || false,
            version: entry.version,
            reason: entry.metadata.deadend.reason || "(unknown)",
        }
        deadends.push(deadend);
    }
    return deadends
}

export interface RolloutEntry {
    kind: string,
    paused: boolean,
    version: string,
    duration_minutes: string,
    start_epoch: string,
    start_percentage: string,
}

export function map_rollouts(updates: any): Array<RolloutEntry> {
    var rollouts: Array<any> = [];
    if (!("releases" in updates)) {
        return rollouts;
    }

    const releases: any = updates.releases;

    for (let entry of releases) {
        if (!("rollout" in entry.metadata)) {
            continue;
        }
        const rollout = {
            kind: "rollout",
            paused: entry.metadata.rollout.paused || false,
            version: entry.version,
            duration_minutes: entry.metadata.rollout.duration_minutes || "0",
            start_epoch: entry.metadata.rollout.start_epoch || "0",
            start_percentage: entry.metadata.rollout.start_percentage || "0",
        }
        rollouts.push(rollout);
    }
    return rollouts
}