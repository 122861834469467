import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import _ from 'lodash';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

function delete_entry(updatesMeta: any, kind: string, version: any) {
  const versionIndex = updatesMeta.releases.findIndex((x: any) => x.version === version);
  if (versionIndex === -1) {
    return
  }
  let meta = { ...updatesMeta.releases[versionIndex].metadata };
  delete meta[kind]
  updatesMeta.releases[versionIndex].metadata = meta;
  if (_.isEmpty(meta)) {
    updatesMeta.releases.splice(versionIndex, 1)
  }
}

function update_entry(updatesMeta: any, kind: string, entry: any) {
  const version = entry.version;
  var versionIndex = updatesMeta.releases.findIndex((x: any) => x.version === version);
  if (versionIndex === -1) {
    const newVersion = {
      version: version,
      metadata: {},
    };
    updatesMeta.releases.push(newVersion);
    versionIndex = updatesMeta.releases.length - 1;
  }
  var meta = { ...updatesMeta.releases[versionIndex].metadata };
  meta[kind] = entry
  updatesMeta.releases[versionIndex].metadata = meta;
}

function rootReducer(stateIn: any, action: any) {
  let state = stateIn ? _.cloneDeep(stateIn) : storeDummy;
  switch (action.type) {
    case 'DELETE_BARRIER':
      delete_entry(state.updates, "barrier", action.payload)
      break;
    case 'DELETE_DEADEND':
      delete_entry(state.updates, "deadend", action.payload)
      break;
    case 'DELETE_ROLLOUT':
      delete_entry(state.updates, "rollout", action.payload)
      break;
    case 'UPDATE_BARRIER':
      update_entry(state.updates, "barrier", action.payload)
      break;
    case 'UPDATE_DEADEND':
      update_entry(state.updates, "deadend", action.payload)
      break;
    case 'UPDATE_ROLLOUT':
      update_entry(state.updates, "rollout", action.payload)
      break;
    case 'RELEASES_UPDATED':
      state.releases = action.payload;
      break;
    case 'UPDATES_UPDATED':
      state.updates = action.payload;
      break;
    case 'LOAD_PANEL':
      state.loadPanelVisible = action.payload.visible;
      break;
    case 'SUBMIT_PANEL':
      state.submitPanelVisible = action.payload.visible;
      break;
    default:
      if (!action.type.startsWith("@@")) {
        console.log("unknown action", action);
      }
      break;
  }
  return state
}

/*
const storeInitValue = {
  "stream": null,
  "releases": [],
  "updates": [],
}
*/

const storeDummy = {
  "stream": {
    "name": "testing",
    "baseOrga": "coreos",
    "forkOrga": "lucab",
    "repository": "fedora-coreos-streams"
  },
  "releases": [],
  "updates": {},
  "loadPanelVisible": false,
  "submitPanelVisible": false
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: storeDummy,
})

/*
const store = createStore(
  rootReducer,
  storeDummy,
  applyMiddleware(thunk)
);
*/

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);