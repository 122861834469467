export async function fetch_releases(stream: String): Promise<Array<String>> {
    const content = await fetch(`https://builds.coreos.fedoraproject.org/prod/streams/${stream}/releases.json`);
    const releases = map_releases(await content.json());
    return releases;
}

export function map_releases(json: any): Array<String> {
    var releases: Array<String> = [];
    for (let entry of json.releases) {
        releases.push(entry.version);
    }
    return releases
}