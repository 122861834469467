import React from 'react';
import { Menubar } from 'primereact/menubar';
import { connect } from 'react-redux';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

function mapStateToProps(state: any): any {
    return {
        metadata: state.updates,
    }
}

const mapDispatchToProps = {
    LoadButtonOnClick,
    SubmitButtonOnClick,
}

function LoadButtonOnClick() {
    return function (dispatch: any, getState: any) {
        dispatch({ 'type': 'LOAD_PANEL', 'payload': { 'visible': true } });
    };
}

function SubmitButtonOnClick() {
    return function (dispatch: any, getState: any) {
        dispatch({ 'type': 'SUBMIT_PANEL', 'payload': { 'visible': true } });
    };
}


class Navbar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            submitPanelVisible: false,
        };
    }

    private model = [
        {
            label: 'Load',
            icon: 'pi pi-fw pi-file',
            command: (_event: any) => {
                this.props.LoadButtonOnClick();
            }
        },
        {
            label: 'Submit',
            icon: 'pi pi-fw pi-upload',
            command: (_event: any) => {
                this.submit(this.props.metadata);
            }
        },
    ]

    start() {
        return <img alt="logo" src="https://getfedora.org/static/images/logo-inline-coreos.png"></img>;

    }

    displayLoadPanel(visible: boolean) {
        this.setState({ loadPanelVisible: visible });
    }

    displaySubmitPanel(visible: boolean) {
        this.setState({ submitPanelVisible: visible });
    }

    async submit(state: any) {
        navigator.clipboard.writeText(JSON.stringify(state, null, 2))
    }

    render() {
        return (
            <div className="p-d-flex">
                <Sidebar position="bottom" className="p-sidebar-lg" visible={this.state.submitPanelVisible} onHide={() => this.displaySubmitPanel(false)}>
                    <div className="p-d-flex">
                        <Button label="Submit" onClick={this.submit} />
                    </div>
                </Sidebar>
                <Menubar id="navbar" model={this.model} start={this.start} >
                </Menubar>
            </div >
        )
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(Navbar);