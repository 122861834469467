import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state: any): any {
    return {
        stream: state.stream.name,
    }
}

const mapDispatchToProps = { }

const connector = connect(mapStateToProps, mapDispatchToProps)

class Picker extends React.Component<any> {
    static defaultProps = {
        'title': ""
    }

    render() {
        return (<div></div>
        )
    }
}

export default connector(Picker);