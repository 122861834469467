import React from 'react';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Sidebar } from 'primereact/sidebar';
import { connect } from 'react-redux';
import * as releaseIndex from '../logic/release-index';
import * as githubCheckout from '../logic/github-checkout';
import { Dropdown } from 'primereact/dropdown';

function mapStateToProps(state: any): any {
    return {
        stream: state.stream.name,
        panelVisible: state.loadPanelVisible,
    }
}

const mapDispatchToProps = {
    LoadButtonOnClick,
    LoadPanelOnHide,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

function LoadPanelOnHide() {
    return function (dispatch: any, getState: any) {
        dispatch({ 'type': 'LOAD_PANEL', 'payload': { 'visible': false } });
    }
}

function LoadButtonOnClick(stream: string, releases: any, updates: any) {
    return function (dispatch: any, getState: any) {
        //        dispatch({ 'type': 'STREAM_UPDATED', 'payload': {} });
        dispatch({ 'type': 'RELEASES_UPDATED', 'payload': releases });
        dispatch({ 'type': 'UPDATES_UPDATED', 'payload': updates });
        dispatch({ 'type': 'LOAD_PANEL', 'payload': { 'visible': false } });
    };
}

class Picker extends React.Component<any, any> {
    private githubUsername = React.createRef<any>()
    private githubToken = React.createRef<any>()
    private stream = React.createRef<any>()

    static defaultProps = {
        stream: "stable",
        panelVisible: false,
    }

    constructor(props: any) {
        super(props);

        this.state = {
            'stream': 'stable',
        };
        this.LoadButtonOnClick = this.LoadButtonOnClick.bind(this);
    }

    async LoadButtonOnClick() {
        const streamLabel = this.state.stream;
        if (streamLabel === "") {
            return;
        }
        const releases = await releaseIndex.fetch_releases(streamLabel);
        const updates = await githubCheckout.checkout_updates(streamLabel);
        if (this.props.stream) {
            this.props.LoadButtonOnClick(streamLabel, releases, updates)
        }
    }

    render() {
        const availableStreams = [
            { 'label': 'stable', value: "stable" },
            { 'label': 'testing', value: "testing" },
            { 'label': 'next', value: "next" }
        ];

        return (
            <Sidebar position="bottom" className="p-sidebar-lg" visible={this.props.panelVisible} onHide={this.props.LoadPanelOnHide} >
                <div className="p-card p-d-flex p-col">
                    <div className="p-col-12 p-md-12">
                        {/*
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">GitHub username:</span>
                            <InputText ref={this.githubUsername} placeholder="TODO" />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">GitHub token:</span>
                            <InputText ref={this.githubToken} placeholder="TODO" />
                        </div>
                        */}
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Stream:</span>
                            <Dropdown ref={this.stream} value={this.state.stream} options={availableStreams} onChange={(e) => { this.setState({ stream: e.value }) }} />
                        </div>
                    </div>
                </div>
                <div className="p-card p-d-flex p-flex-column p-col">
                    <div className="p-d-flex p-row p-jc-center">
                        <Button label="Load" onClick={this.LoadButtonOnClick} />
                    </div>
                    <div className="p-d-flex p-row p-col">
                        <div className="p-col-12 p-md-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">GitHub auth:</span>
                                <span className="p-inputgroup-addon p-tag p-tag-secondary"><i className="pi pi-minus"></i></span>
                                <InputText placeholder="<status unknown>" disabled />
                            </div>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">GitHub checkout:</span>
                                <span className="p-inputgroup-addon p-tag p-tag-secondary"><i className="pi pi-minus"></i></span>
                                <InputText placeholder="<status unknown>" disabled />
                            </div>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Metadata fetch:</span>
                                <span className="p-inputgroup-addon p-tag p-tag-secondary"><i className="pi pi-minus"></i></span>
                                <InputText placeholder="<status unknown>" disabled />
                            </div>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Release index:</span>
                                <span className="p-inputgroup-addon p-tag p-tag-secondary"><i className="pi pi-minus"></i></span>
                                <InputText placeholder="<status unknown>" disabled />
                            </div>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Updates metadata:</span>
                                <span className="p-inputgroup-addon p-tag p-tag-secondary"><i className="pi pi-minus"></i></span>
                                <InputText placeholder="<status unknown>" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        )
    }
}

export default connector(Picker);