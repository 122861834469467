import * as updateMetadata from '../logic/updates-metadata';
import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

function mapStateToProps(state: any) {
    return {
        'editable': state.releases.length > 0,
        'barriers': updateMetadata.map_barriers(state.updates),
        'deadends': updateMetadata.map_deadends(state.updates),
        'rollouts': updateMetadata.map_rollouts(state.updates)
    }
}

const mapDispatchToProps = {
    AddEntry,
    DeleteEntry,
    UpdateEntry,
}

function AddEntry(kind: string, entry: any) {
    return function (dispatch: any, getState: any) {
        dispatch({ 'type': kind, 'payload': entry });
    }
}

function DeleteEntry(table: string, payload: any) {
    const kind = `DELETE_${table.toUpperCase()}`;
    return function (dispatch: any, getState: any) {
        dispatch({ 'type': kind, 'payload': payload });
    }
}

function UpdateEntry(table: string, payload: any) {
    const kind = `UPDATE_${table.toUpperCase()}`;
    return function (dispatch: any, getState: any) {
        dispatch({ 'type': kind, 'payload': payload });
    }
}

export class UpdatesTable extends React.Component<any, any> {
    static defaultProps = {
        'editable': false,
        'barriers': [],
        'deadends': [],
        'rollouts': []
    }

    constructor(props: any) {
        super(props);

        this.state = {
            'barriersPending': {},
            'deadendsPending': {},
            'rolloutsPending': {},
        };
        this.statusEditor = this.statusEditor.bind(this);
        this.textEditor = this.textEditor.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.addRollout = this.addRollout.bind(this);
        this.addBarrier = this.addBarrier.bind(this);
        this.addDeadend = this.addDeadend.bind(this);
    }

    onRowEditInit(event: any) {
        const version = event.data.version;
        const kind = event.data.kind || "";

        this.setState((prevState: any) => {
            var barriers = { ...prevState.barriersPending };
            var deadends = { ...prevState.deadendsPending };
            var rollouts = { ...prevState.rolloutsPending };

            switch (kind) {
                case "barrier":
                    barriers[version] = event.data;
                    break;
                case "deadend":
                    deadends[version] = event.data;
                    break;
                case "rollout":
                    rollouts[version] = event.data;
                    break;
            }
            return {
                editable: prevState.editable,
                barriersPending: barriers,
                deadendsPending: deadends,
                rolloutsPending: rollouts,
            }
        })
    }

    onRowEditSave(event: any) {
        const origVersion = event.data.version;
        const kind = event.data.kind || "";
        let table = "";
        switch (kind) {
            case 'barrier':
                table = "barriersPending"
                break;
            case 'deadend':
                table = "deadendsPending"
                break;
            case 'rollout':
                table = "rolloutsPending"
                break;
            default:
                return;
        }
        let entry = { ...this.state[table][origVersion] }
        if (entry.paused === "deleted") {
            this.props.DeleteEntry(kind, origVersion)
            return;
        }

        if (entry.version !== origVersion) {
            this.props.DeleteEntry(kind, origVersion)
        }
        this.props.UpdateEntry(kind, entry)
    }

    onRowEditCancel(event: any) {
        const version = event.data.version;
        const kind = event.data.kind || "";

        this.setState((prevState: any) => {
            var barriers = { ...prevState.barriersPending };
            var deadends = { ...prevState.deadendsPending };
            var rollouts = { ...prevState.rolloutsPending };

            switch (kind) {
                case "barrier":
                    delete barriers[version]
                    break;
                case "deadend":
                    delete deadends[version]
                    break;
                case "rollout":
                    delete rollouts[version]
                    break;
            }

            return {
                editable: prevState.editable,
                barriersPending: barriers,
                deadendsPending: deadends,
                rolloutsPending: rollouts,
            }
        })

    }

    textEditor(props: any) {
        if (!("rowData" in props)) {
            return undefined;
        }
        if (!("rowIndex" in props)) {
            return undefined;
        }
        if (!("field" in props)) {
            return undefined;
        }

        const version = props.rowData.version;
        var pendingTable = "";
        switch (props.rowData.kind) {
            case 'barrier':
                pendingTable = "barriersPending";
                break;
            case 'deadend':
                pendingTable = "deadendsPending";
                break;
            case 'rollout':
                pendingTable = "rolloutsPending";
                break;
            default:
                return undefined;
        }

        let value = this.state[pendingTable]?.[version]?.[props.field];
        if (value === undefined) {
            value = "";
        }

        const component = this;
        const setValue = function (ev: any) {
            var row = { ...component.state[pendingTable][version] };
            row[props.field] = ev.target.value;
            component.setState({
                [pendingTable]: {
                    [version]: row,
                }
            })
            return ev.value;
        }

        return <InputText type="text" value={value} onChange={setValue} />;
    }

    statusEditor(props: any) {
        const version = props.rowData?.version;
        const kind = props.rowData?.kind;
        if (version === undefined || kind === undefined) {
            return undefined;
        }

        if (!("rowIndex" in props)) {
            return undefined;
        }
        if (!("field" in props)) {
            return undefined;
        }

        let table: string;
        switch (kind) {
            case 'barrier':
                table = "barriersPending";
                break;
            case 'deadend':
                table = "deadendsPending";
                break;
            case 'rollout':
                table = "rolloutsPending";
                break;
            default:
                return undefined;
        }
        let curValue = this.state[table]?.[version]?.paused;
        if (curValue === undefined) {
            curValue = true;
        }

        const choices = [
            { 'label': 'Active', 'value': false },
            { 'label': 'Paused', 'value': true },
            { 'label': 'Deleted', 'value': 'deleted' },
        ];

        const component = this;
        const setValue = function (ev: any) {
            var row = { ...component.state[table][version] };
            row.paused = ev.value;
            component.setState({
                [table]: {
                    [version]: row,
                }
            })
        }

        return <Dropdown options={choices} value={curValue} onChange={setValue}></Dropdown>
    }

    statusField(props: any) {
        const active = <span className="p-tag p-tag-l p-tag-success">Active</span>;
        const paused = <span className="p-tag p-tag-l p-tag-warning">Paused</span>;

        const isPaused = props.paused || false;

        if (isPaused) {
            return (paused)
        } else {
            return (active)
        }
    }

    addBarrier() {
        const version = 'TBD'
        const entry = {
            paused: true,
            version: version,
            reason: "n/a",
        };
        this.props.UpdateEntry("barrier", entry)
    }

    addDeadend() {
        const version = 'TBD'
        const entry = {
            paused: true,
            version: version,
            reason: "n/a",
        };
        this.props.UpdateEntry("deadend", entry)
    }

    addRollout() {
        const version = 'TBD'
        const entry = {
            paused: true,
            version: version,
            duration_minutes: "0",
            start_epoch: "0",
            start_percentage: "0",
        };
        this.props.UpdateEntry("rollout", entry)
    }

    render() {
        return (
            <Accordion id="updates-accordion" multiple className="p-d-flex" activeIndex={[2]}>
                <AccordionTab header={<React.Fragment><i className="pi pi-fw pi-step-forward"></i><span>Barriers</span></React.Fragment>}>
                    <div className="p-col">
                        <DataTable
                            value={this.props.barriers}
                            editMode="row"
                            dataKey="version"
                            onRowEditInit={this.onRowEditInit}
                            onRowEditSave={this.onRowEditSave}
                            onRowEditCancel={this.onRowEditCancel}
                        >
                            <Column field="status" header="Status" body={this.statusField} editor={this.statusEditor}></Column>
                            <Column field="version" header="Version" editor={this.textEditor} ></Column>
                            <Column field="reason" header="Reason" editor={this.textEditor}></Column>
                            <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>
                    </div>
                    <div className="p-col" hidden={!this.props.editable}>
                        <Button label="Add barrier" onClick={this.addBarrier} />
                    </div>
                </AccordionTab>
                <AccordionTab header={<React.Fragment><i className="pi pi-fw pi-minus-circle"></i><span>Dead-ends</span></React.Fragment>}>
                    <div className="p-col">
                        <DataTable
                            value={this.props.deadends}
                            editMode="row"
                            dataKey="version"
                            onRowEditInit={this.onRowEditInit}
                            onRowEditSave={this.onRowEditSave}
                            onRowEditCancel={this.onRowEditCancel}
                        >
                            <Column field="status" header="Status" body={this.statusField} editor={this.statusEditor}></Column>
                            <Column field="version" header="Version" editor={this.textEditor}></Column>
                            <Column field="reason" header="Reason" editor={this.textEditor}></Column>
                            <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>
                    </div>
                    <div className="p-col" hidden={!this.props.editable}>
                        <Button label="Add deadend" onClick={this.addDeadend} />
                    </div>
                </AccordionTab>
                <AccordionTab header={<React.Fragment><i className="pi pi-fw pi-calendar"></i><span>Rollouts</span></React.Fragment>}>
                    <div className="p-col">
                        <DataTable
                            value={this.props.rollouts}
                            editMode="row"
                            dataKey="version"
                            onRowEditInit={this.onRowEditInit}
                            onRowEditSave={this.onRowEditSave}
                            onRowEditCancel={this.onRowEditCancel}
                        >
                            <Column field="status" header="Status" body={this.statusField} editor={this.statusEditor}></Column>
                            <Column field="version" header="Version" editor={this.textEditor}></Column>
                            <Column field="duration_minutes" header="Duration" editor={this.textEditor}></Column>
                            <Column field="start_epoch" header="Start Time" editor={this.textEditor}></Column>
                            <Column field="start_percentage" header="Start Percentage" editor={this.textEditor}></Column>
                            <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>
                    </div>
                    <div className="p-col" hidden={!this.props.editable}>
                        <Button label="Add rollout" onClick={this.addRollout} />
                    </div>
                </AccordionTab>
            </Accordion>
        );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(UpdatesTable);