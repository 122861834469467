import React from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';

function mapStateToProps(state: any): any {
    return {
        stream: state.stream.name,
    }
}

const mapDispatchToProps = {}

class Picker extends React.Component<any> {
    static defaultProps = {
        stream: null,
    }

    render() {
        return (
            <div id="picker" className="p-d-flex">
                <Panel header="Stream summary" className="p-col" toggleable collapsed>
                    <div className="p-d-flex p-flex-wrap">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Stream</span>
                            <InputText value="" disabled />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Nodes</span>
                            <InputText value="" disabled />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Edges</span>
                            <InputText value="" disabled />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Barriers</span>
                            <InputText value="" disabled />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Dead-ends</span>
                            <InputText value="" disabled />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Rollouts</span>
                            <InputText value="" disabled />
                        </div>
                    </div>
                </Panel>
            </div>
        )
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(Picker);