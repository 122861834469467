import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Picker from './components/Picker';
import UpdatesTable from './components/UpdatesTable';
import UpdatesGraph from './components/UpdatesGraph';
import { Panel } from 'primereact/panel';
import LoadPanel from './components/LoadPanel';
import SubmitPanel from './components/SubmitPanel';

// TODO: encapsulate components.

function App() {
  return (
    <div className="App">
      <Navbar />
      <Picker />
      <div id="workspace" className="p-d-flex">
        <Panel header="Updates configuration" className="p-col p-d-flex p-flex-column">
          <UpdatesTable />
        </Panel>
        <Panel header="Updates graph" className="p-col p-d-flex p-flex-column">
          <UpdatesGraph />
        </Panel>
      </div>
      <LoadPanel />
      <SubmitPanel />
    </div >
  );
}

export default App;
