import * as graphlib from "graphlib";

export function build(releases: any, updates: any): [Array<any>, Array<[number, number]>] {
    var nodes: Array<any> = releases;
    var edges: Array<any> = compute_edges(releases, updates);
    return [nodes, edges];
}

function compute_edges(releases: any, updates: any): Array<[number, number]> {
    if (updates?.releases === undefined) {
        return [];
    }

    // Collect entries into sorted arrays.
    var barriers: number[] = [];
    var deadends: number[] = [];
    var rollouts: number[] = [];
    updates.releases.forEach((element: any) => {
        const targetIndex: number = releases.findIndex((el: string) => el === element.version);
        if (targetIndex < 0) {
            return;
        }
        if (element?.metadata?.barrier !== undefined) {
            if (!element.metadata.barrier.paused) {
                barriers.push(targetIndex);
            }
        }
        if (element?.metadata?.deadend !== undefined) {
            if (!element.metadata.deadend.paused) {
                deadends.push(targetIndex);
            }
        }
        if (element?.metadata?.rollout !== undefined) {
            if (!element.metadata.rollout.paused) {
                rollouts.push(targetIndex);
            }
        }
    });
    let edges: Array<[number, number]> = [];
    const lastBarrier = barriers[barriers.length - 1] || -1;
    rollouts.forEach((targetIndex: any) => {
        releases.forEach((_val: any, sourceIndex: number) => {
            if (sourceIndex in deadends) {
                return;
            }
            if (targetIndex > sourceIndex && sourceIndex >= lastBarrier) {
                edges.push([sourceIndex, targetIndex]);
            }
        })
    });

    let previousBarrier = -1;
    barriers.forEach((targetIndex: any) => {
        releases.forEach((_val: any, sourceIndex: number) => {
            if (sourceIndex in deadends) {
                return;
            }
            if (targetIndex > sourceIndex && sourceIndex >= previousBarrier) {
                edges.push([sourceIndex, targetIndex]);
            }
        })
        previousBarrier = targetIndex;
    });

    return edges;
}

export function render(nodes: Array<any>, edges: Array<any>): any {
    let graph = new graphlib.Graph().setGraph({ "rankdir": "LR" });
    nodes.forEach((value, index) => {
        let nodeProps = {
            'label': value,
            'class': "",
            'rx': 5,
            'ry': 5
        };
        graph.setNode(String(index), nodeProps);
    });

    for (let edge of edges) {
        graph.setEdge(String(edge[0]), String(edge[1]), {});
    }
    return graph;
}